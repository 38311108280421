import { api } from '@/lib/api';
import { EventCodePayload } from './eventCodes.types';

const createCode = (payload: EventCodePayload) => {
  return api.post('/odds/platform/sc-codes/', payload);
};

const getCodes = (params: any) => {
  return api.get('/odds/platform/get-sc-codes/', {
    params,
    cache: {
      maxAge: 0,
    },
  });
};

export const eventCodesRepo = { createCode, getCodes };
