import to from 'await-to-js';
import Vue from 'vue';
import { Action, getModule, Mutation, Module, VuexModule } from 'vuex-module-decorators';
import {
  CompetitionsMap,
  GetCompetitionsParams,
  ICompetition,
  UpdateCreateCompetitionPayload,
} from './competition.types';
import store from '@/store';
import { competitionService } from './competition.service';
import { CommonStore } from '../common';
import { objToArr } from '@/lib/objToArr';
import { ApiListResponse } from '@/types/api';

const initialState = (): { [key: string]: any } => ({
  competitions: {},
  totalCompetitions: 0,
  betradarCompetitions: [],
  betgeniusCompetitions: [],
  statscoreCompetitions: [],
  activeId: null,
});

const arrayToObject = (array: any[]): object => {
  return array.reduce((obj: any, item: any) => {
    obj[`c-${item.id}`] = item;
    return obj;
  }, {});
};

@Module({ dynamic: true, store, name: 'competition', namespaced: true })
class Competition extends VuexModule {
  public competitions: CompetitionsMap = initialState().competitions;
  public totalCompetitions = initialState().totalCompetitions;
  public betradarCompetitions = initialState().betradarCompetitions;
  public betgeniusCompetitions = initialState().betgeniusCompetitions;
  public statscoreCompetitions = initialState().statscoreCompetitions;
  public activeId: number | null = initialState().activeId;

  get competitionsArray() {
    return objToArr(this.competitions);
  }

  get activeCompetition() {
    if (!this.activeId) return null;
    return this.competitions[`c-${this.activeId}`];
  }

  @Mutation
  private setCompetitions({ count, results }: ApiListResponse<ICompetition>): void {
    this.competitions = { ...this.competitions, ...arrayToObject(results) };
    this.totalCompetitions = count;
  }

  @Mutation
  public clearCompetitions(): void {
    this.competitions = {};
    this.totalCompetitions = 0;
  }

  @Mutation
  public setSelectedCompetition(id: number): void {
    this.activeId = id;
  }

  @Mutation
  resetStore(): void {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  deselectCompetition(): void {
    this.activeId = null;
  }

  @Mutation
  editCompetition({
    competitionId,
    key,
    newValue,
  }: {
    competitionId: number;
    key: keyof ICompetition;
    newValue: any;
  }) {
    Vue.set(this.competitions[`c-${competitionId}`], key, newValue);
  }

  @Mutation
  private setBetRadarIds(betradarCompetitions: ICompetition[]): void {
    this.betradarCompetitions = betradarCompetitions;
  }

  @Mutation
  private setBetGeniusIds(betgeniusCompetitions: ICompetition[]): void {
    this.betgeniusCompetitions = betgeniusCompetitions;
  }

  @Mutation
  setStatscoreIds(statscoreCompetititons: ICompetition[]): void {
    this.statscoreCompetitions = statscoreCompetititons;
  }

  @Mutation
  private updateCompetitionById({ id, newData }: { id: number; newData: Partial<ICompetition> }) {
    Vue.set(this.competitions, `c-${id}`, { ...this.competitions[`c-${id}`], ...newData });
  }

  @Action
  async fetchCompetitions(params: GetCompetitionsParams): Promise<void> {
    CommonStore.setIsFetching(true);
    const [err, res] = await to(competitionService.getCompetitions(params));
    CommonStore.setIsFetching(false);
    if (err) return;
    this.setCompetitions(res as any);
  }

  @Action
  async fetchBetRadarIds(payload: { name: string; sport_id: number }): Promise<void> {
    const searchQuery: any = { ...payload, has_betradar_ids: true, is_confirmed: false };
    const [err, result] = await to(competitionService.fetchBetRadarIds(searchQuery));
    if (err) return;
    this.setBetRadarIds(result.results);
  }

  @Action
  async fetchBetGeniusIds(payload: { name: string; sport_id: number }): Promise<void> {
    const searchQuery: any = { ...payload, has_betgenius_ids: true, is_confirmed: false };
    const [err, result] = await to(competitionService.fetchBetGeniusIds(searchQuery));
    if (err) return;
    this.setBetGeniusIds(result.results);
  }

  @Action
  async fetchStatscoreIds(payload: { name: string; sport_id: number }): Promise<void> {
    const searchQuery: any = { ...payload, has_statscore_ids: true, is_confirmed: false };
    const [err, result] = await to(competitionService.fetchStatscoreIds(searchQuery));
    if (err) return;
    this.setStatscoreIds(result.results);
  }

  @Action
  async saveCompetition(competitionPayload: UpdateCreateCompetitionPayload): Promise<any> {
    CommonStore.setIsFetching(true);
    const [err, res] = await to(competitionService.saveCompetition(competitionPayload));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    if (competitionPayload.id) {
      this.updateCompetitionById({
        id: competitionPayload.id,
        newData: res as any,
      });
    }
    return Promise.resolve(res);
  }

  @Action
  async updateFavorite(obj: any): Promise<void> {
    const [err] = await to(competitionService.updateFavorite(obj.id, !obj.is_favorite));
    if (err) return;
    this.editCompetition({ competitionId: obj.id, key: 'is_favorite', newValue: !obj.is_favorite });
  }

  @Action
  async saveTemplates({
    id,
    formData,
    hasPlayerTemplate,
  }: {
    id: number;
    formData: any;
    hasPlayerTemplate: boolean;
  }) {
    const [err] = await to(competitionService.saveTemplates(id, formData, hasPlayerTemplate));
    if (err) return Promise.reject(err);
    this.updateCompetitionById({
      id,
      newData: formData,
    });
    return Promise.resolve();
  }

  @Action
  async removeSelectedItem(): Promise<any> {
    if (!this.activeCompetition) return Promise.reject();
    const [err, res] = await to(competitionService.removeSelectedItem(this.activeCompetition.id));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }
}

export const CompetitionStore = getModule(Competition);
