import { eventCodesRepo } from './eventCodes.repo';
import { EventCodePayload } from './eventCodes.types';

class EventCodesService {
  createCode(payload: EventCodePayload) {
    return eventCodesRepo.createCode(payload);
  }

  getCodes(params: any) {
    return eventCodesRepo.getCodes(params);
  }

  formatEventCodes(eventCodes: any) {
    const codeNames = Object.keys(eventCodes);
    return codeNames.map(name => ({ name: name, ...eventCodes[name] }));
  }

  filterCodesBySportName(
    codes: any[],
    selectedSports: string[] | null,
    selectedLocations: string[] = [],
    selectedCompetitions: string[] = []
  ) {
    if (!selectedSports?.length) return codes;
    return codes.filter(code => {
      return (
        selectedSports.some(sport => {
          return code.name?.toLowerCase().includes(sport?.toLowerCase());
        }) &&
        selectedLocations.some(location =>
          code.name?.toLowerCase().includes(location?.toLowerCase())
        ) &&
        selectedCompetitions.some(competition =>
          code.name?.toLowerCase().includes(competition?.toLowerCase())
        )
      );
    });
  }
}

export const eventCodesService = new EventCodesService();
