
import { Mixins, Component } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { mapForSelect } from '@/lib';

import { TableContainer } from '@/ui/components/TableContainer';
import { SportFilter } from '@/ui/components/SportFilter';
import { WithFilters } from '@/ui/mixins/WithFilters';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { SportStore } from '@/modules/sport';
import { LocationStore } from '@/modules/location';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { SportSource } from '@/modules/sportFilter/sportFilter.types';
import { FilterConfig } from '@/modules/filter/filter.types';
import { EventCodesStore } from '@/modules/eventCodes';
import { EVENT_TYPES_FOR_SELECT } from '@/modules/eventCodes/eventCodes.constants';

import EventCodeForm from './components/EventCodeForm.vue';

@Component({
  components: {
    TableContainer,
    SportFilter,
    EventCodeForm,
  },
})
export default class EventCodesPage extends Mixins(WithDataTable, WithFilters) {
  lastSelectedRowNode = {};
  showNoLocation = false;
  isLoading = false;
  form!: WrappedFormUtils;
  selectedSport = 0;
  selectedLocation = 0;
  selectedCompetition = 0;
  selectedType = '';
  eventTypesForSelect = EVENT_TYPES_FOR_SELECT;

  filterConfig: FilterConfig = [];

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('eventCodes.name'),
          field: 'name',
          flex: 1,
        },
        {
          headerName: this.$t('eventCodes.from'),
          field: 'code_from',
          width: 200,
        },
        {
          headerName: this.$t('eventCodes.to'),
          field: 'code_to',
          width: 200,
        },
      ],
      rowData: this.tableData,
    };
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return EventCodesStore.eventCodesArray.filter((code: any) => {
      const isSport = this.selectedSport ? code.sport_id === this.selectedSport : true;
      const isLocation = this.selectedLocation ? code.location_id === this.selectedLocation : true;
      const isCompetition = this.selectedCompetition
        ? code.competition_id === this.selectedCompetition
        : true;
      const isType = this.selectedType ? code.name.includes(`${this.selectedType}-`) : true;
      return isSport && isLocation && isCompetition && isType;
    });
  }

  get totalRecords() {
    return EventCodesStore.totalEventCodes;
  }

  get sports() {
    return mapForSelect(SportFilterStore.sports) || [];
  }

  get locations() {
    if (!this.selectedSport) return [];
    return mapForSelect(SportFilterStore.locationBySport(this.selectedSport));
  }

  get competitions() {
    if (!this.selectedSport || !this.selectedLocation) return [];
    return mapForSelect(
      SportFilterStore.competitionByLocationsAndSport(this.selectedLocation, this.selectedSport)
    );
  }

  get canFetchData() {
    return this.sports.length > 0;
  }

  get source() {
    return SportSource;
  }

  get selectedCompetitions() {
    return SportFilterStore.parsedSelectedCompetitions;
  }

  onFilterSubmit(e: any) {
    e.preventDefault();
    this.gridApi?.setDatasource(this.dataSource);
    this.gridApi?.showLoadingOverlay();
  }

  clearFilters() {
    this.form.resetFields();
    this.gridApi?.setDatasource(this.dataSource);
    this.gridApi?.showLoadingOverlay();
  }

  onSportSelect(selectedSport: number) {
    this.selectedSport = selectedSport;
  }

  onLocationSelect(selectedLocation: number) {
    this.selectedLocation = selectedLocation;
  }

  onCompetitionSelect(selectedCompetition: number) {
    this.selectedCompetition = selectedCompetition;
  }

  onTypeSelect(selectedType: string) {
    this.selectedType = selectedType;
  }

  mounted() {
    SportFilterStore.loadData(this.source.factory);
    SportStore.fetchSports();
    LocationStore.fetchLocations();
    this.fetchData();
  }

  refetchData() {
    EventCodesStore.resetStore();
    this.fetchData();
  }

  async fetchData(sort?: string[]): Promise<void> {
    const ordering = sort?.length ? { ordering: sort[0] } : {};
    const eventCodesPayload = {
      limit: this.limit,
      offset: this.offset,
      ...this.activeFilters,
      ...ordering,
    };
    await EventCodesStore.getEventCodes(eventCodesPayload);
  }

  beforeDestroy() {
    EventCodesStore.resetStore();
  }

  setShowNoLocation() {
    this.showNoLocation = !this.showNoLocation;
  }
}
