import { api } from '@/lib/api';
import { CompetitionTemplatesPayload, ICompetition } from '.';
import {
  GetBetradarCompetitions,
  GetBetgenuisCompetitions,
  GetCompetitionsParams,
  UpdateCreateCompetitionPayload,
  GetStatscoreCompetitions,
} from './competition.types';

const BASE_ROUTE = 'odds/competitions/';

const createCompetition = (approval: ICompetition) => api.post(BASE_ROUTE, approval);

const getCompetitions = (params?: GetCompetitionsParams) => {
  return api.get(BASE_ROUTE, { params, cache: { maxAge: 0 } }) as Promise<any[]>;
};

const saveCompetition = (payload: UpdateCreateCompetitionPayload) => {
  return api.post(BASE_ROUTE, payload);
};

const updateCompetition = (id: number, payload: UpdateCreateCompetitionPayload) => {
  const url = `${BASE_ROUTE}${id}/`;
  return api.put(url, payload);
};

const fetchBetRadarIds = (params: GetBetradarCompetitions) => {
  return api.get(BASE_ROUTE, { params }) as Promise<any>;
};

const fetchBetGeniusIds = (params: GetBetgenuisCompetitions) => {
  return api.get(BASE_ROUTE, { params }) as Promise<any>;
};

const fetchStatscoreIds = (params: GetStatscoreCompetitions) => {
  return api.get(BASE_ROUTE, { params }) as Promise<any>;
};

const removeSelectedItem = (id: number) => {
  return api.delete(`${BASE_ROUTE}${id}/`);
};

const updateFavorite = (id: number, is_favorite: boolean) => {
  const url = `${BASE_ROUTE}${id}/favorite/`;
  return api.put(url, {
    is_favorite,
  }) as Promise<any[]>;
};

const saveTemplates = (id: number, data: CompetitionTemplatesPayload) => {
  return api.put(`${BASE_ROUTE}${id}/templates/`, data);
};

export const competitionRepo = {
  createCompetition,
  getCompetitions,
  saveCompetition,
  updateCompetition,
  removeSelectedItem,
  fetchBetRadarIds,
  updateFavorite,
  saveTemplates,
  fetchBetGeniusIds,
  fetchStatscoreIds,
};
