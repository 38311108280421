import omit from 'lodash/omit';

import { competitionRepo } from './competition.repo';
import {
  CompetitionTemplatesPayload,
  GetBetradarCompetitions,
  UpdateCreateCompetitionPayload,
  GetCompetitionsParams,
  ICompetition,
  GetBetgenuisCompetitions,
  GetStatscoreCompetitions,
} from './competition.types';

class CompetitionService {
  getCompetitions(params: GetCompetitionsParams) {
    return competitionRepo.getCompetitions(params);
  }

  saveCompetition(data: UpdateCreateCompetitionPayload) {
    if (!data.id) {
      const obj: any = omit(data, 'id');
      return competitionRepo.saveCompetition(obj);
    } else {
      const id = data.id;
      return competitionRepo.updateCompetition(id, data);
    }
  }

  updateFavorite(id: number, is_favorite: boolean) {
    return competitionRepo.updateFavorite(id, is_favorite);
  }

  fetchBetRadarIds(payload: GetBetradarCompetitions) {
    return competitionRepo.fetchBetRadarIds(payload);
  }

  fetchBetGeniusIds(payload: GetBetgenuisCompetitions) {
    return competitionRepo.fetchBetGeniusIds(payload);
  }

  fetchStatscoreIds(payload: GetStatscoreCompetitions) {
    return competitionRepo.fetchStatscoreIds(payload);
  }

  removeSelectedItem(id: number | null) {
    return competitionRepo.removeSelectedItem(id as number);
  }

  parseTemplateRequest(data: any, hasPlayerTemplate: boolean): CompetitionTemplatesPayload {
    const requestPayload: any = hasPlayerTemplate
      ? { ...data, player_approval_template_id: data.prematch_approval_template_id }
      : data;

    return requestPayload as CompetitionTemplatesPayload;
  }

  saveTemplates(id: number, data: any, hasPlayerTemplate: boolean) {
    return competitionRepo.saveTemplates(id, this.parseTemplateRequest(data, hasPlayerTemplate));
  }

  parseValues(
    provider_ids: any[],
    idsMap: { [key: string]: number },
    competitionsMap: any,
    provider: string
  ) {
    return provider_ids.reduce((acc: any, id: number | string) => {
      if (typeof id === 'string') {
        return [...acc, idsMap[id]];
      }
      return [
        ...acc,
        ...competitionsMap.find((competition: any) => competition.id === id)[`${provider}_ids`],
      ];
    }, []);
  }

  getProviderLocation(obj: any, provider: string): string {
    if (!obj[`${provider}_ids`]) return '';
    const newLocations = obj[`${provider}_ids`].reduce((acc: any, val: any) => {
      if (!obj.provider_data[`${provider}_${val}`]) return [];
      const location = obj.provider_data[`${provider}_${val}`].location.name;
      return [...acc, location];
    }, []);
    return newLocations.join(',');
  }

  mapProviderCompetitionsSelect(array: any, provider: string) {
    if (!array) return [];
    return array.map((item: any) => {
      return {
        value: item.id,
        label: `${item.name} (${this.getProviderLocation(item, provider)})`,
      };
    });
  }

  setNamesReprensetation(
    competition: ICompetition | null,
    setNames: Function,
    providerIdsMap: any,
    provider: string
  ): void {
    const tempNames: any = [];
    if (competition && competition.provider_data) {
      const providerDataKeys: string[] = Object.keys(competition.provider_data).filter(key =>
        key.includes(provider)
      );
      providerDataKeys.forEach((key: string) => {
        tempNames.push({
          id: Number(key.slice(key.indexOf('_') + 1)),
          name: `${competition?.provider_data[key].name}(${competition?.provider_data[key].location.name})`,
        });
      });
      const namesArray = tempNames.map((item: any) => item.name);
      setNames(namesArray);
      tempNames.forEach((item: any) => (providerIdsMap[item.name] = item.id));
      return;
    }
    setNames([]);
  }
}

export const competitionService = new CompetitionService();
