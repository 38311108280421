import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import to from 'await-to-js';
import { eventCodesService } from './eventCodes.service';
import { CommonStore } from '../common';

const initialState = () => ({
  totalEventCodes: 0,
  eventCodes: [],
  activeEventCodeId: 0,
});
@Module({ dynamic: true, store, name: 'eventCodes', namespaced: true })
class EventCodes extends VuexModule {
  activeEventCodeId = initialState().activeEventCodeId;
  totalEventCodes = initialState().totalEventCodes;
  eventCodes: any = initialState().eventCodes;

  get eventCodesArray() {
    return eventCodesService.formatEventCodes(this.eventCodes);
  }

  @Mutation
  setEventCodes(response: any) {
    this.eventCodes = { ...this.eventCodes, ...response };
    this.totalEventCodes = Object.values(response).length;
  }

  @Mutation
  deselectCode() {
    this.activeEventCodeId = 0;
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach((key: any) => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  setActiveId(activeEventCodeId: number) {
    this.activeEventCodeId = activeEventCodeId;
  }

  @Action
  async getEventCodes(params: any) {
    CommonStore.setIsFetching(true);
    const [err, res] = await to<any>(eventCodesService.getCodes(params));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    this.setEventCodes(res as any);
    return Promise.resolve();
  }

  @Action
  async createCode(params: any) {
    CommonStore.setIsFetching(true);
    const [err, result] = await to<any>(eventCodesService.createCode(params));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    Promise.resolve(result);
  }
}

export const EventCodesStore = getModule(EventCodes);
