
import to from 'await-to-js';
import { TranslateResult } from 'vue-i18n';
import { Component, Mixins } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import { mapForSelect } from '@/lib';
import { SportStore } from '@/modules/sport';
import { LocationStore } from '@/modules/location';
import { competitionService } from '@/modules/competition';
import { InputProps } from '@/modules/common/common.types';
import { CompetitionStore } from '@/modules/competition/competition.store';
import { EventCodesStore } from '@/modules/eventCodes';
import { EVENT_TYPES_FOR_SELECT } from '@/modules/eventCodes/eventCodes.constants';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';

@Component
export default class EventCodeForm extends Mixins(WithNetworkStatus) {
  declare $style: any;
  form!: WrappedFormUtils;
  eventTypesForSelect = EVENT_TYPES_FOR_SELECT;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  get locationsForSelect() {
    return mapForSelect(LocationStore.getAllLocations);
  }

  get sportsForSelect() {
    return mapForSelect(SportStore.allSports);
  }

  get competitionsForSelect() {
    // provider should be dymamic
    return competitionService.mapProviderCompetitionsSelect(
      CompetitionStore.competitionsArray,
      'betradar'
    );
  }

  get successMessageDescription() {
    return this.$t('eventCodes.newCodeCreated');
  }

  onLocationChange(locationId: number | undefined) {
    if (!locationId) return;
    const sportId = this.form.getFieldValue('sport_id');
    const fetchCompetitionsParams: any = {
      is_confirmed: true,
      sport_id: sportId,
      location_id: locationId,
    };
    CompetitionStore.clearCompetitions();
    CompetitionStore.fetchCompetitions(fetchCompetitionsParams);
  }

  makeInput(
    label: TranslateResult,
    accessor: string,
    isRequired = false,
    errorMessage: string | TranslateResult = ''
  ) {
    return {
      type: 'a-input-number',
      span: 24,
      label: label,
      class: this.$style.inputAndLabel,
      placeholder: label,
      vDecorator: [
        `${accessor}`,
        {
          rules: [
            {
              required: isRequired,
              message: errorMessage,
            },
          ],
        },
      ],
    };
  }

  makeSelect(
    label: TranslateResult,
    selectProps: any,
    accessor: string,
    initialValue: any,
    isRequired = false,
    errorMessage: string | TranslateResult = '',
    span = 24,
    rules = {},
    onChange?: any
  ) {
    return {
      span: span,
      type: 'a-select',
      label: label,
      class: this.$style.inputAndLabel,
      placeholder: label,
      allowClear: true,
      selectProps: selectProps,
      onChange: onChange,
      optionFilterProp: 'children',
      showSearch: true,
      vDecorator: [
        `${accessor}`,
        {
          initialValue: initialValue,
          rules: [
            {
              required: isRequired,
              message: errorMessage,
              ...rules,
            },
          ],
        },
      ],
    };
  }

  get formInputs(): InputProps[] {
    return [
      this.makeSelect(
        this.$t('common.sport'),
        {
          showSearch: true,
          options: this.sportsForSelect,
        },
        'sport_id',
        undefined,
        false,
        this.$t(`competition.selectSportError`)
      ),
      this.makeSelect(
        this.$t('common.location'),
        {
          showSearch: true,
          options: this.locationsForSelect,
        },
        'location_id',
        undefined,
        false,
        this.$t(`competition.selectLocationError`),
        24,
        {},
        this.onLocationChange
      ),
      this.makeSelect(
        this.$t('common.competition'),
        {
          showSearch: true,
          options: this.competitionsForSelect,
        },
        'competition_id',
        undefined,
        false,
        this.$t(`competition.selectCompetitionError`)
      ),
      this.makeSelect(
        this.$t('eventCodes.type'),
        {
          showSearch: true,
          options: this.eventTypesForSelect,
        },
        'event_type',
        undefined,
        true,
        this.$t(`competition.selectLocationError`)
      ),
      this.makeInput(
        this.$t('eventCodes.from'),
        'from',
        true,
        this.$t('common.requiredValidationError')
      ),
      this.makeInput(
        this.$t('eventCodes.to'),
        'to',
        true,
        this.$t('common.requiredValidationError')
      ),
    ];
  }

  errorMessage(description: TranslateResult) {
    this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage(description: TranslateResult) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async onSave() {
    const [formErr] = await to(this.form.validateFields() as any);
    if (formErr) return this.errorMessage(this.$t('common.allFieldsRequired'));
    const formData = this.form.getFieldsValue();
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(EventCodesStore.createCode(formData));
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.successMessage(this.successMessageDescription);
    this.form.resetFields();
    this.$emit('created');
  }
}
